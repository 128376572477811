/* eslint-disable max-len */
import Backdrop from '@common_backdrop';
import Badge from '@common_badge';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { getCmsBlocks } from '@core_modules/cms/services/graphql';

const Copyright = (props) => {
    const {
        loading,
        error,
        storeConfig,
        pageType,
    } = props;

    const isCheckoutPage = pageType === 'checkout';
    const isPDP = pageType === 'product';

    const { t } = useTranslation(['common']);

    const { data: dataCms, loading: loadingCms } = getCmsBlocks(
        { identifiers: isCheckoutPage ? 'footer_social_links_checkout' : 'footer_social_links' },
    );

    if (error) {
        return <Badge danger>{t('common:error:fetchError')}</Badge>;
    }
    if (loading) return <Backdrop open={loading} />;

    if (!dataCms?.cmsBlocks?.items[0]?.content) return null;

    return (
        <>
            <div className={
                cx(
                    'copyright', 'bg-neutral-white', 'border-t-[1px]', 'border-zinc-100',
                    isCheckoutPage && 'mobile:pb-[100px]',
                    isPDP && 'desktop:pb-[85px] tablet:pb-[65px] mobile:pb-[85px]',
                )
            }
            >
                <div className={
                    cx('flex text-center my-0 mx-auto py-4 px-10 flex-col mobile:flex-row desktop:max-w-[1440px] tablet:max-w-[768px] mobile:text-left')
                }
                >
                    <div className={
                        cx(
                            'w-full mobile:flex-col mobile:text-center tablet:flex-col',
                            !isCheckoutPage && 'flex',
                            isCheckoutPage && 'desktop:grid desktop:grid-cols-2 tablet:grid tablet:grid-cols-2 mobile:pb-space-1200',
                            isCheckoutPage && 'mobile:flex',
                        )
                    }
                    >
                        <span className={
                            cx(
                                'mobile:w-full tablet:w-full tablet:text-center tablet:mt-8 tablet:order-1 desktop:w-max text-base tablet:text-sm font-normal leading-5 text-neutral-black text-left mobile:text-center whitespace-nowrap',
                                !isCheckoutPage && 'w-2/5 self-center',
                                isCheckoutPage && 'flex desktop:justify-end tablet:justify-end mobile:justify-center mobile:pb-[12px]',
                            )
                        }
                        >
                            {storeConfig.copyright}
                        </span>
                        {!loadingCms ? <CmsRenderer content={dataCms?.cmsBlocks?.items[0]?.content} storeConfig={storeConfig} /> : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Copyright;
