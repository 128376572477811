import React from 'react';
import Router from 'next/router';
import cx from 'classnames';

const PageProgressLoader = () => {
    const [progress, setProgress] = React.useState();
    const [show, setShow] = React.useState(false);
    const [initLoadCount, setInitLoadCount] = React.useState(0);
    const timer = React.useRef(null);
    const timerStuck = React.useRef(null);

    const wrapperClasses = cx('fixed', 'w-full', 'left-0', 'top-0', 'z-[1100]');

    const handleRouteChangeStart = () => {
        setProgress(0);
        setShow(true);
        timer.current = setInterval(() => {
            setProgress((oldProgress) => {
                const diff = Math.random() * 10;
                const addProgress = oldProgress + diff;
                const newProgress = addProgress > 90 ? 90 : addProgress;
                return oldProgress === 100 ? 100 : Math.min(newProgress, 90);
            });
        }, 500);
    };

    const handleRouteChangeComplete = () => {
        clearInterval(timer.current);
        setProgress(100);
        setTimeout(() => {
            setShow(false);
            sessionStorage.setItem('currentUrl', Router.asPath);
        }, 2000);
    };

    const handleRouteChangeError = () => {
        clearInterval(timer.current);
        setProgress(100);
        setTimeout(() => {
            setShow(false);
        }, 500);
    };

    React.useEffect(() => {
        Router.events.on('routeChangeStart', handleRouteChangeStart);
        Router.events.on('routeChangeComplete', handleRouteChangeComplete);
        Router.events.on('RouteChangeError', handleRouteChangeError);
        return () => {
            clearTimeout(timerStuck.current);
            clearTimeout(timer.current);
        };
    }, []);

    // handle timer stuck for 12secs
    React.useEffect(() => {
        if (progress >= 90 && progress <= 99 && initLoadCount < 1) {
            setInitLoadCount((prev) => prev + 1);
            timerStuck.current = setTimeout(() => {
                setProgress(100);
                setInitLoadCount(0);
                clearTimeout(timerStuck.current);
            }, 12000);
        }
    }, [progress, initLoadCount]);

    return (
        <>
            {show ? (
                <div className={cx(wrapperClasses)}>
                    <div className={cx('w-full', 'bg-neutral-50', 'h-1')}>
                        <div className={cx('bg-primary-700', 'h-1')} style={{ width: `${progress}%`, transition: '1s ease-in-out' }} />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default PageProgressLoader;
